/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        /* BREADCRUMB REPLACE TEXT */
        var breadcrumbs = $('.breadcrumbs'),
          home = breadcrumbs.find('.home'),
          span = home.find('span'),
          mtp = breadcrumbs.find('.post-montpellier-archive'),
          mtp_span = mtp.find('span'),
          litt = breadcrumbs.find('.post-littoral-archive'),
          litt_span = litt.find('span');

        span.text('Institution');
        mtp_span.text('La Merci Montpellier');
        litt_span.text('La Merci Littoral');
        /* END BREADCRUMB REPLACE TEXT */
        $('.showFormButton').each(function() {
          $(this).on('click', function() {
            var $formContainer = $(this).next();
            $formContainer.toggle();
          });
        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $('header.banner .hamburger').click(function (e) {
          e.preventDefault();
          $(this).toggleClass('active');
          $('header.banner .nav-header').stop(true, true).slideToggle(500);
        });

        $('header.banner .nav-header li.menu-item-has-children > a').click(function (e) {
          if ($(window).width() <= 1150) {
            e.preventDefault();
            var target = $(this);
            var parentTarget = target.parent('li');
            target.next('ul').stop(true, true).slideToggle(400);
            parentTarget.toggleClass('active-element');
          }
        });
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
        if ($(".slider-pre-home").length) {
          $(".slider-pre-home").each(function (index) {
            var sliderID = $(this).data('slideid');
            $('.slider-pre-home.slide_' + sliderID).slick({
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
              autoplay: true,
              autoplaySpeed: 4000,
              speed: 600,
              dots: false,
              adaptiveHeight: true
            });
          });
        }
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
        if ($(".banner-alert").length && Cookies.get("alert-header") !== $(".banner-alert").data("id").toString()) {
          $(".banner-alert").delay(1000).slideDown(800);

          $(".close").click(function () {
            Cookies.set("alert-header", $(".banner-alert").data("id"), {expires: 365});
            $(".banner-alert").slideUp(250);
          });
        }
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    },
    'single': {
      init: function () {
        if ($(".container-info-video").length) {
          $(".container-icon-video").flexVerticalCenter({cssAttribute: 'top', parentSelector: '.container-info-video'});

          $(".icon-play-video").click(function () {
            $(".bloc-video").css("padding", "0");
            $('.bloc-video iframe')[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
            $(".bloc-video .content").toggle();
            $(".bloc-video .iframe-wrapper").toggle();
          });
        }

        // Sliders
        if ($(".slider-home .illus").length) {
          // SLIDER HOME
          $('.slider-home .illus').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '.slider-home .desc',
            speed: 600
          });
          $('.slider-home .desc').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: '.slider-home .illus',
            dots: true,
            autoplay: true,
            autoplaySpeed: 4000,
            speed: 600
          });
        }

        $('.slider-focus').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 4000,
          speed: 600
        });

        if ($(".container-slider-content").length) {
          $(".container-slider-content").each(function (index) {
            var sliderID = $(this).data('slideid');
            $('.container-slider-content.slide_' + sliderID + ' .slides-image-content').slick({
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
              fade: true,
              asNavFor: '.container-slider-content.slide_' + sliderID + ' .slides-text-content',
              speed: 600,
            });
            $('.container-slider-content.slide_' + sliderID + ' .slides-text-content').slick({
              slidesToShow: 1,
              slidesToScroll: 1,
              // arrows: false,
              asNavFor: '.container-slider-content.slide_' + sliderID + ' .slides-image-content',
              autoplay: true,
              autoplaySpeed: 4000,
              speed: 600,
              dots: true,
              adaptiveHeight: true,
            });
            // $('.slider-prev').click(function(){
            //   $('.container-slider-content.slide_' + sliderID + ' .slides-image-content').slick('slickPrev');
            // })

            // $('.slider-next').click(function(){
            //   $('.container-slider-content.slide_' + sliderID + ' .slides-text-content').slick('slickNext');
            // })
          });
        }
        if ($(".slides-temoignages").length) {
          $(".slides-temoignages").each(function (index) {
            var sliderID = $(this).data('slideid');
            $('.slides-temoignages.slide_' + sliderID).slick({
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
              autoplay: false,
              autoplaySpeed: 4000,
              speed: 600,
              dots: true,
              adaptiveHeight: true
            });
          });
        }
        const scrolltopBlock = document.querySelector('.bloc-scrolltop');
        ;
        if (scrolltopBlock !== null) {
          scrolltopBlock.toggleActiveClass = function () {
            if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight) {
              scrolltopBlock.classList.add('active');
            } else {
              scrolltopBlock.classList.remove('active');
            }
          };

          // init
          scrolltopBlock.toggleActiveClass();
          // init on scroll
          window.onscroll = function () {
            scrolltopBlock.toggleActiveClass()
          };
        }
        if ($(".icon.scrolltop").length) {
          $('.icon.scrolltop').on('click', function () {
            //var page = $(this).attr('href');
            var speed = 500;
            $('html, body').animate({scrollTop: 0}, speed);
            return false;
          });
        }
        if ($(".icon.ancre").length) {
          $('.icon.ancre').on('click', function () {
            var page = $(this).data('href');
            var speed = 500;
            $('html, body').animate({scrollTop: $('#' + page).offset().top}, speed);
            return false;
          });
        }

        /**************************************
         ACCORDION SYSTEM
         **************************************/

        // => OLD JS CODE FOR ACCORDION
        // if ($(".panel-group")[0]) {
        //   $('#accordion')
        //     .on('show.bs.collapse', function (e) {
        //       $(e.target).prev('.panel-heading').find('a:first-child').addClass('collapse-active');
        //       //console.log('test');
        //     })
        //     .on('hide.bs.collapse', function (e) {
        //       //console.log('test2');
        //       $(e.target).prev('.panel-heading').find('a:first-child').removeClass('collapse-active');
        //     });
        // }

        $('body').on('click', '.btn-accordion', accordionToggling);

        function accordionToggling(e) {
          e.preventDefault();
          const animationSpeed = 500;
          const $clickedTitle = $(this).closest('.panel-heading');
          const $currentAccordion = $clickedTitle.closest('.panel-group');
          const $activeTitle = $currentAccordion.find('.panel-heading.active');
          const isOpenedYet = ($clickedTitle.hasClass('active')) ? true : false;
          const anotherIsOpenedYet = ($activeTitle.length) ? true : false;

          if (isOpenedYet) {
            $clickedTitle.removeClass('active');
            $clickedTitle.next().stop().slideUp(animationSpeed);
          } else {
            if (anotherIsOpenedYet) {
              $activeTitle.removeClass('active');
              $activeTitle.next().stop().slideUp(animationSpeed, function () {
                $clickedTitle.addClass('active');
                $clickedTitle.next().stop().slideDown(animationSpeed);
              });
            } else {
              $clickedTitle.addClass('active');
              $clickedTitle.next().stop().slideDown(animationSpeed);
            }
          }
        }

        /**************************************
         END ACCORDION SYSTEM
         **************************************/

        /**************************************
         ANCHOR SMOOTHING SCROLL
         **************************************/
        $('.ancre').on('click', function (e) {
          e.preventDefault();
          $('html, body').animate({
            scrollTop: $(this.hash).offset().top
          }, 1000);
        });
        /**************************************
         END ANCHOR SMOOTHING SCROLL
         **************************************/

        /* MENU ADD CLASS WHEN ACTIVE */
        $("nav [href]").each(function () {
          $(".menu > li.current-menu-ancestor > a").addClass("active");
          if (this.href === window.location.href) {
            $(this).addClass("active");
          }
        });
        /* END MENU ADD CLASS WHEN ACTIVE */

        /**************************************
         TABLE : REMOVE EMPTY TH
         **************************************/
        $('table th').each(function () {
          //var th = $(this).find('th').text();
          if ($(this).text() === '') {
            var thead = $(this).find('thead');
            //th = thead.find('th');
            var th = $(this).closest('table').children('thead').children('tr').children('th');
            var nbtd = $(this).closest('table').children('tbody').children('tr:first').children('td').length;
            //console.log(nbtd);
            th.attr('colspan', nbtd);
            th.attr('scope', 'colgroup');
            $(this).remove();
          }
        });
        /**************************************
         END TABLE : REMOVE EMPTY TH
         **************************************/

        // OLD CODE
        // $('.know-more').click(function () {
        //   var btn = $(this).find('button');
        //   btn.hide();
        // });
        $('.know-more').each(function (index, elem) {
          $(elem).find('[type="button"]').on('click', function (e) {
            e.preventDefault();
            $(this).hide();
            $(this).next().slideDown();
          });
        });
      }
    },
    'single_montpellier': {
      init: function () {
        // console.log('Hello single-montpellier');

        $('.item.agenda .list a, .sidebar .rencontre .agenda a').click(function (e) {
          e.preventDefault();
          var month = $(this).find('.month').val(),
            root = $(location).attr('href').replace($(location).attr('pathname'), ''),
            url = root.concat('/montpellier/agenda/');

          $.redirect(url, {'month': month});
        });

        $('.item.groupe .sub-item.communiques a').click(function (e) {
          e.preventDefault();
          var date = $(this).find('.communiques-date').val(),
            root = $(location).attr('href').replace($(location).attr('pathname'), ''),
            url = root.concat('/montpellier/communiques/');
          $.redirect(url, {'date': date});
        });

        $('.item.agenda').css("min-height", $('.item.groupe').outerHeight());

      }
    },
    'single_littoral': {
      init: function () {

        $('.item.agenda .list a, .sidebar .rencontre .agenda a').click(function (e) {
          e.preventDefault();
          var month = $(this).find('.month').val(),
            root = $(location).attr('href').replace($(location).attr('pathname'), ''),
            url = root.concat('/littoral/agenda/');

          $.redirect(url, {'month': month});
        });

        $('.item.groupe .sub-item.communiques a').click(function (e) {
          e.preventDefault();
          var date = $(this).find('.communiques-date').val(),
            root = $(location).attr('href').replace($(location).attr('pathname'), ''),
            url = root.concat('/littoral/communiques/');
          $.redirect(url, {'date': date});
        });

        $('.item.agenda').css("min-height", $('.item.groupe').outerHeight());
      }
    },
    'communiques': {
      init: function () {
        $('.communiques-btn').on('click', function () {
          var state = $(this).data('state');
          switch (state) {
            case 1:
            case undefined:
              $(this).toggleClass('active');
              $(this).data('state', 2);
              break;
            case 2:
              $(this).toggleClass('active');
              $(this).data('state', 1);
              break;
          }
        });

        var show_more = $('.show_more');
        var total = $('.communiques-txt').length;
        var other = total - 6;
        show_more.hide();


        if (total > 6) {
          $(".communiques-txt:gt(5)").hide();
          show_more.show();
          show_more.on('click', function () {
            $(".communiques-txt:gt(5)").slideDown(500);
            other = other + 6;
            if (other <= total) {
              show_more.hide();
            }
          });
        }

        var posted_date = $('.posted_date').val();

        if (posted_date) {
          $('.panel-collapse').each(function () {
            if ($(this).hasClass(posted_date)) {
              $('html, body').animate({scrollTop: $(this).closest('div.communiques-txt').offset().top}, 500);
              $(this).collapse();
              $(this).parent().find('a.communiques-btn').toggleClass('active');
            }
          });
        }
      }
    },
    'plan_dacces': {
      init: function () {
        // console.log('Hello plan d\'accès');
      }
    },
    'agenda': {
      init: function () {

        $('.agenda .nav-tab').slick({
          infinite: false,
          slidesToShow: 4,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1150,
              settings: {
                slidesToShow: 3
              }
            },
            {
              breakpoint: 750,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1
              }
            }
          ]
        });

        var posted_month = $('.posted_month').val();
        if (posted_month != null) {
          var dataTab = '#'.concat(posted_month);
          $('.agenda .tab-pane').hide();
          $('.agenda .tab-pane' + dataTab).show();
          $(dataTab).addClass('active');
          var index = $(dataTab).parent().attr('data-slick-index');
          $('.agenda .nav-tab').slick('slickGoTo', index, true);

        } else {
          var months = ["janvier", "fevrier", "mars", "avril", "mai", "juin",
            "juillet", "aout", "septembre", "octobre", "novembre", "decembre"];

          var d = new Date(),
            month = d.getMonth(),
            month_fr = months[month],
            month_id = '#'.concat(month_fr + '-' + d.getFullYear()),
            indexToGet = $('.agenda .nav-tab').find(month_id).closest('[data-slick-index]').attr('data-slick-index'),
            a = $('.agenda .nav-tab').find($(month_id)),
            dataTab2 = a.attr('data-toggle'),
            slidesToShow = $('.agenda .nav-tab').get(0).slick.options.slidesToShow,
            slideCount = $('.agenda .nav-tab').get(0).slick.slideCount;
          if (indexToGet) {
            $('.agenda .nav-tab .item a').removeClass('active');
            $(a).addClass('active');
            $('.agenda .tab-pane').hide();
            $('.agenda .tab-pane' + dataTab2).show();
            while (indexToGet > 0 && slideCount - indexToGet < slidesToShow) {
              indexToGet--;
            }
            $('.agenda .nav-tab').slick('slickGoTo', indexToGet, false);
          } else {
            var m = ((new Date().getMonth() + 1) < 10) ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1);
            var today = Number(new Date().getFullYear() + '' + m);
            var $tab = $('.agenda .nav-tab [data-month]:first');
            slidesToShow = $('.agenda .nav-tab').get(0).slick.options.slidesToShow;
            var tabFound = false;
            while (tabFound === false) {
              if ($tab.data('month') >= today) {
                tabFound = true;
              } else {
                $tab = $tab.closest('.slick-slide').next().find('[data-month]');
                if ($tab.length === 0) {
                  $tab = $('.agenda .nav-tab [data-month]:last');
                  tabFound = true;
                }
              }
            }
            var nextMonthTab = $tab.attr('data-toggle');
            var indexNextMonth = $tab.closest('[data-slick-index]').attr('data-slick-index')
            $('.agenda .nav-tab .item a').removeClass('active');
            $tab.addClass('active');
            $('.agenda .tab-pane').hide();
            $('.agenda .tab-pane' + nextMonthTab).show();
            while (indexNextMonth > 0 && slideCount - indexNextMonth < slidesToShow) {
              indexNextMonth--;
            }
            $('.agenda .nav-tab').slick('slickGoTo', indexNextMonth, false);
          }
        }

        $('.agenda .nav-tab .item a').click(function (e) {
          e.preventDefault();
          var dataTab = $(this).data('toggle');
          $('.agenda .nav-tab .item a').removeClass('active');
          $(this).addClass('active');
          $('.agenda .tab-pane').hide();
          $('.agenda .tab-pane' + dataTab).show();
        });
      }
    },
    'cat_medias': {
      init: function () {
        //console.log('Hello medias');
        /* Masonry + Infinite Scroll */
        var $container = $('#grid-container');
        $container.imagesLoaded(function () {
          $container.masonry({
            itemSelector: '.post',
            columnWidth: 365,
            isFitWidth: true
          });
        });
        $container.infinitescroll({
          navSelector: '#page-nav',
          nextSelector: '#page-nav a',
          itemSelector: '#grid-container .post',
          loading: {
            msgText: 'Chargement en cours ...',
            finishedMsg: 'Fin des contenus médias.',
            img: templateUrl + '/dist/images/loading.gif'
          },
          errorCallback: function () {
            $('#page-nav a').remove();
          }
        }, function (newElements) {
          var $newElems = $(newElements).css({
            opacity: 0
          });
          $newElems.imagesLoaded(function () {
            $newElems.animate({
              opacity: 1
            });
            $container.masonry('appended', $newElems, true);
          });
        });
        //$(window).unbind('.infscr'); // Désactive le scroll infini (click manuel sur le bouton en bas de page pour charger les médias)
        jQuery('#page-nav a').click(function () {
          jQuery('#grid-container').infinitescroll('retrieve');
          return false;
        });
        $(document).ajaxError(function (e, xhr, opt) {
          if (xhr.status === 404) {
            $('#page-nav a').remove();
          }
        });

        /* Fancybox */
        $('#grid-container').on('focusin', function () {
          $("a.fancybox").fancybox({
            maxWidth: 853,
            maxHeight: 480,
            autoSize: false,
            fitToView: true,
            padding: 0
          });
          $('a.fancybox.video').click(function () {
            $.fancybox.showLoading();

            var wrap = $('<div id="dummy"></div>').appendTo('body');
            var el = $(this).clone().appendTo(wrap);

            el.oembed(null, {
              embedMethod: 'replace',
              afterEmbed: function (rez) {
                var what = $(rez.code);
                var type = 'html';
                var scrolling = 'no';

                if (rez.type === 'photo') {
                  what = what.find('img:first').attr('src');
                  type = 'image';
                } else if (rez.type === 'rich') {
                  scrolling = 'auto';
                }

                what[0].setAttribute('src', what[0].src.replace('http://', 'https://'));

                $.fancybox.open({
                  href: what,
                  type: type,
                  scrolling: scrolling,
                  title: rez.title || $(this).attr('title'),
                  padding: 0,
                  width: 853,
                  height: 480,
                  autoSize: false,
                  fitToView: true
                });
              },
              onError: function () {
                $.fancybox.open(this/*, {
                               openEffect	: 'elastic',
                               closeEffect	: 'elastic'
                           }*/);
              }
            });
            wrap.remove();
            return false;
          });
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  function getCookie(name) {
    var re = new RegExp(name + "=([^;]+)");
    var value = re.exec(document.cookie);
    return (value != null) ? unescape(value[1]) : 'no';
  }

  var mapContainer = $('#map');
  if (mapContainer.length > 0) {
    if (getCookie('cookielawinfo-checkbox-functional') === 'no') {
      var tpl = '<div style="width:100%; height:400px; max-height:80vh; position:absolute;top:0;left:0" class="wt-cli-iframe-placeholder"><div class="wt-cli-inner-text"><a class="cli_manage_current_consent" href="javascript:void(0)" style="position:relative;z-index:2">' + mapContainer.attr('wt-cli-mention') + '</a></div></div>';

      mapContainer.empty();
      mapContainer.html(tpl);

    }
  }
})(jQuery); // Fully reference jQuery after this point.


